<script>


/**
 * Fee Setup  component
 */



import {
    required,
} from "vuelidate/lib/validators";



export default {

    emits: ['confirm', 'cancel'],

    props: ['fee'],
   
    components: {

    },

    validations: {
       fee : {
        amount : {required}
       }
    },



    data() {

        return {
            submitted: false,
        }

    },
    methods: {
        formSubmit() {
            this.submitted = true;
            this.$v.$touch();
           
            if (this.$v.$error == false) {
                this.$emit('confirm', this.fee)
            }
        },

    },

    created() {

       
    },

    mounted() {
        
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="fee_list_choice" class="form-label font-size-13 text-muted"  >Fee Name</label>
                            <input id="validationCustom01" v-model="fee.name" type="text" class="form-control"
                                readonly="true"  />
                           
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom01">Code</label>
                            <input id="validationCustom01" v-model="fee.code" type="text" class="form-control"
                                readonly="true" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom01">{{fee.fee_type =='RATE' ? 'Rate(%)' :'Amount'}}</label>
                            <input id="validationCustom01" v-model="fee.amount" type="text" class="form-control"
                                placeholder="$"  :class="{
                                    'is-invalid':  $v.fee.amount.$error,
                                }" />
                            <div v-if=" $v.fee.amount.$error" class="invalid-feedback">
                                <span v-if="!$v.fee.amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                   
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="fee_list_choice" class="form-label font-size-13 text-muted"  >Note</label>
                            <input id="validationCustom01" v-model="fee.note" type="text" class="form-control"
                                placeholder="Note"   />
                           
                        </div>
                    </div>
                </div>



                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>